import React, { useMemo } from "react";
import { Tree, hierarchy } from "@visx/hierarchy";
import { LinkVertical } from "@visx/shape";
import { Group } from "@visx/group";
import "./styles.css";

// const nodeWidth = 96 + 24 + 96;
// const nodeHeight = 84;

const nodeWidth = 12;
const nodeHeight = 12;

const rawTree = {
  title: "CEO",
  employee: "Catilan Miller",
  children: [
    {
      title: "VP of Sales",
      employee: "Jane Sanders",
      children: [
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
          children: [
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
          ],
        },
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
          children: [
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
          ],
        },
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
          children: [
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
              children: [
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
              ],
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
              children: [
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
              ],
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
          ],
        },
      ],
    },
    {
      title: "VP of Sales",
      employee: "Jane Sanders",
      children: [
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
          children: [
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
          ],
        },
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
        },
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
        },
      ],
    },
    {
      title: "VP of Sales",
      employee: "Jane Sanders",
    },
    {
      title: "VP of Sales",
      employee: "Jane Sanders",
      children: [
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
        },
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
        },
      ],
    },
    {
      title: "VP of Sales",
      employee: "Jane Sanders",
    },
    {
      title: "VP of Sales",
      employee: "Jane Sanders",
      children: [
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
        },
        {
          title: "VP of Sales",
          employee: "Jane Sanders",
          children: [
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
              children: [
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
                {
                  title: "VP of Sales",
                  employee: "Jane Sanders",
                },
              ],
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
            {
              title: "VP of Sales",
              employee: "Jane Sanders",
            },
          ],
        },
      ],
    },
  ],
};

// const Position = ({ x, y, title, employee }) => (
//   <circle cx={x} cy={y} r={6} />
//   // <>
//   //   <rect
//   //     x={x - nodeWidth / 2}
//   //     y={y - 24}
//   //     width={nodeWidth}
//   //     height={nodeHeight}
//   //     fill="#0003"
//   //     stroke="none"
//   //   />
//   //   <Group left={x} top={y}>
//   //     <rect x={-24} y={-24} width={48} height={48} fill="#000" />
//   //     {/* <text
//   //       y={24 + 18}
//   //       textAnchor="middle"
//   //       fontSize="16"
//   //       fontFamily="sans-serif"
//   //     >
//   //       {title}
//   //     </text>
//   //     <text
//   //       y={24 + 18 * 2}
//   //       textAnchor="middle"
//   //       fontSize="16"
//   //       fontFamily="sans-serif"
//   //     >
//   //       {employee}
//   //     </text> */}
//   //     <text
//   //       y={10}
//   //       textAnchor="middle"
//   //       fontSize="24"
//   //       fontFamily="sans-serif"
//   //       fill="#fff"
//   //     >
//   //       MM
//   //     </text>
//   //   </Group>
//   // </>
// );

const getDepth = (node) =>
  node.children?.length
    ? node.children.reduce(
        (result, child) => Math.max(result, getDepth(child)),
        0
      ) + 1
    : 1;

const getBreadth = (node) =>
  node.children?.length
    ? node.children.reduce((result, child) => result + getBreadth(child), 0)
    : 1;

const ListItem = ({ node }) => (
  <li>
    <span>{node.title}</span>
    {node.children?.length ? (
      <ul>
        {node.children.map((child) => (
          <ListItem node={child} />
        ))}
      </ul>
    ) : null}
  </li>
);
export default function App() {
  const data = useMemo(() => hierarchy(rawTree), []);
  const height = nodeHeight * getDepth(data) + 48 * (getDepth(data) - 1);
  const width = nodeWidth * getBreadth(data);
  // const width = 96 * 8 + 24 * 7;
  return (
    <div
      style={{
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: `${width}px auto`,
      }}
    >
      <svg width={width} height={height}>
        <Tree
          root={data}
          size={[width, height - nodeHeight]}
          separation={() => 1}
        >
          {(tree) => (
            <Group top={6} left={0}>
              {tree.links().map((link, i) => (
                <LinkVertical
                  key={`link-${i}`}
                  data={link}
                  stroke="#ccc"
                  strokeWidth="2"
                  fill="none"
                />
              ))}
              {tree.descendants().map((node, i) => (
                <circle key={`node-${i}`} cx={node.x} cy={node.y} r={6} />
              ))}
            </Group>
          )}
        </Tree>
      </svg>
      <ul>
        <ListItem node={rawTree} />
      </ul>
    </div>
  );
}
